@import "~@ng-select/ng-select/themes/default.theme.css";

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}

body {
  color: var(--text-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: var(--background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

body {
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--text-primary);
  border-radius: 100px;
}

.logo {
  cursor: pointer;
}

.appito-container {
  max-width: 1200px;
  padding: 0 16px;
  margin: 0 auto;
  display: flex;
  width: 100%;
}

.link-default {
  color: var(--green-secondary);
  text-decoration: none;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}

.btn {
  display: flex;
  border: none;
  height: 32px;
  padding: 8px 12px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 14px;
  color: var(--text-black);
  font-family: "Degular Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-decoration: none;
  cursor: pointer;

  &.btn-radius-16 {
    border-radius: 16px;
  }

  &.btn-radius-100 {
    border-radius: 100px;
  }

  &-primary {
    @extend .btn;
    color: var(--text-black);
    background: var(--brand-green-primary, #c4f54b);

    &:hover,
    &:focus {
      background: #bfec4f;
      color: var(--text-black);
    }

    &:active {
      color: var(--text-black);
      background: #bbe650;
    }
  }

  &-secondary {
    @extend .btn;
    color: var(--green-secondary);
    background: var(--fills-fill-light-16, rgba(124, 128, 120, 0.16));

    &:hover,
    &:focus {
      color: var(--green-secondary);
      background: darken(rgba(124, 128, 120, 0.16), 12%);
    }

    &:active {
      color: var(--green-secondary);
      background: darken(rgba(124, 128, 120, 0.16), 16%);
    }
  }

  &.btn-large {
    display: flex;
    height: 48px;
    padding: 14px 16px 16px 16px;
    gap: 8px;
    font-family: "Degular Display";
    font-size: 18px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
  }

  &.btn-small {
    display: flex;
    padding: 8px 16px 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    line-height: 16px;
    width: fit-content;
  }

  &[disabled],
  &.disabled {
    border-radius: 14px;
    background: var(--fills-fill-light-8, rgba(124, 128, 120, 0.08));
    color: var(--text-tertiary);
    pointer-events: none;
  }

  &.btn-full {
    width: 100%;
  }
}

/**********
   LAYOUT
***********/
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
.mdc-linear-progress__buffer-bar {
  background: var(--labels-text-quaternary, rgba(65, 67, 60, 0.18));
}

.mdc-linear-progress__bar-inner {
  border-color: var(--green-secondary) !important;
}

.line {
  height: 1px;
  background: rgba(65, 67, 60, 0.16);
  width: 100%;
}

.section-title-and-subtitle {
  padding: 40px 16px;
  margin: 0 auto;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  p {
    max-width: 480px;
  }
}

.section-card-row {
  display: flex;
  justify-content: center;
  gap: 16px;

  .app-card {
    display: flex;
    max-width: 360px;
    flex-direction: column;
    flex: 1 0 0;
    border-radius: 16px;
    background: var(--background-surface, #fff);
    overflow: hidden;

    picture img {
      width: 100%;
    }

    .btn-primary {
      display: flex;
      height: 48px;
      padding: 14px 16px 16px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      width: 100%;
    }

    &__description {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
    }

    &__chips {
      display: flex;
      gap: 4px;
      padding-bottom: 16px;
    }

    &__chip {
      display: flex;
      padding: 4px 8px;
      gap: 10px;
      border-radius: 8px;
      background: var(--fills-fill-light-12, rgba(124, 128, 120, 0.12));
    }
  }
}

/**********
   FORMULARIO
***********/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-control {
  display: flex;
  height: 44px;
  padding: 10px 12px;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  border: 0 none;
  background: var(--background-surface, #fff);
  color: var(--labels-text-black, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &::placeholder {
    color: #000;
    opacity: 0.38;
  }

  &,
  &:-webkit-autofill {
    background: var(--background-surface, #fff) !important;
  }

  &:focus,
  &:focus-visible,
  &:active {
    box-shadow: none;
    border: none;
  }

  &.disabled,
  &[disabled] {
    color: rgba(0, 0, 0, 0.38) !important;

    &,
    &:-webkit-autofill {
      background: var(--fills-fill-light-8, rgba(124, 128, 120, 0.08));
    }
  }
}

.form-group__inline {
  display: flex;
  width: 100%;
  height: 44px;
  padding: 10px 12px;
  align-items: center;
  border-radius: 16px;
  background: var(--background-surface, #fff);
  label {
    color: var(--labels-text-secondary, rgba(65, 67, 60, 0.6));

    /* Inter/Regular/Int-16-regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 80px;
  }

  input {
    display: flex;
    height: 24px;
    align-items: center;
    gap: 1px;
    border: 0 none !important;
  }

  &.disabled {
    background: var(--fills-fill-light-8, rgba(124, 128, 120, 0.08));
    label {
      color: var(--text-primary);
      opacity: 0.38;
    }
    .form-control {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.38);
      pointer-events: none;

      &::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.38);
      }

      &:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.38);
      }

      &::placeholder {
        color: rgba(0, 0, 0, 0.38);
      }
    }
  }

  &--radio {
    @extend .form-group__inline;
    height: 48px;
    .radio {
      height: 24px;
      display: flex;
      align-items: center;
      &__label {
        width: auto;
      }
    }
  }

  &--with-button {
    @extend .form-group__inline;
    input {
      padding: 0;
    }
  }
}

.form-grouped {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  &--united {
    @extend .form-grouped;
    gap: 0;
    border-radius: 16px;
    overflow: hidden;
    background: var(--background-surface, #fff);

    &.disabled {
      background: none;
    }
    .form-group__inline {
      border-radius: 0;
    }

    .divider {
      right: -12px;
    }
  }
}

.divider {
  width: 100%;
  height: 0;
  position: relative;
  border-top: solid 1px rgba(65, 67, 60, 0.16);
}

.chip-drop-container {
  position: relative;
}
.chip-drop {
  display: flex;
  height: 30px;
  padding: 8px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: var(--brand-green-primary, #c4f54b);
}

.chip-group {
  display: flex;
  align-items: flex-start;
  gap: 2px;

  .chip {
    background: var(--fills-fill-light-16, rgba(124, 128, 120, 0.16));
  }

  .chip--disabled {
    @extend .chip;
    pointer-events: none;
    color: var(--text-tertiary);
    background: var(--fills-fill-light-8, rgba(124, 128, 120, 0.08));
  }

  .chip--active {
    @extend .chip;
    background: var(--brand-green-primary, #c4f54b);
  }

  .chip:first-child {
    border-radius: 20px 4px 4px 20px;
  }

  .chip:last-child {
    border-radius: 4px 20px 20px 4px;
  }
}

.chip {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 24px;
  cursor: pointer;
}

/**********
   DROPDOWN
***********/

.ng-select.custom {
  .ng-select-container {
    border-radius: 16px;
    background: var(--background-surface, #fff);
    border: none;

    .ng-input > input {
      height: 32px;
    }
  }
  &.ng-select-single .ng-select-container {
    height: 44px;
  }

  &.ng-select-opened.ng-select-bottom > .ng-select-container {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .ng-select-container .ng-value-container {
    padding-left: 12px;
  }

  .ng-arrow-wrapper {
    padding-right: 12px;
    .ng-arrow {
      border: 0 none;
      height: 100%;
      display: flex;
      width: 18px;
      margin-right: 0;
      font-size: 18px;
      color: #c6c6c6;

      &:before {
        font-family: "Material Symbols Outlined";
        font-weight: normal;
        font-style: normal;
        font-size: 18px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
        content: "expand_more";
      }
    }
  }

  &.ng-select-opened .ng-arrow-wrapper .ng-arrow:before {
    content: "expand_less";
  }
}

.ng-dropdown-panel.custom {
  max-width: 220px !important;
  width: 100% !important;
  border-radius: 16px;
  margin-top: 5px;
  box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(60px);
  overflow: hidden;
  border: none !important;

  &,
  .ng-dropdown-panel-items .ng-option {
    background: #e7e8e5;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e7e8e5;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    height: 40px;
    background: var(--labels-text-tertiary, rgba(65, 67, 60, 0.3));
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(rgba(65, 67, 60, 0.5));
  }

  .ng-dropdown-panel-items .ng-option {
    padding: 0;
  }

  .ng-select-custom-option {
    display: flex;
    height: 46px;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }

  .ng-dropdown-panel-items .ng-option.ng-option-marked,
  .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background: var(--fills-fill-light-8, rgba(124, 128, 120, 0.08));
  }
}
.dropdown-menu.show {
  display: flex;
}
.dropdown-menu {
  display: none;
  padding: 2px 0px;
  flex-direction: column;
  border-radius: 16px;
  background: var(--menu-menu-fill, rgba(241, 243, 238, 0.16));
  box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.2);
  border: none;
  backdrop-filter: blur(60px);
  min-width: 220px;
  overflow: hidden;

  li:last-child .dropdown-item {
    border-bottom: none;
  }
  .dropdown-item {
    display: flex;
    height: 46px;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-bottom: solid 1px #41433c29;
    cursor: pointer;

    &:hover {
      color: inherit !important;
      background-color: #7c80781f;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

/**********
  LOGIN
***********/

.dropdown-menu-logged {
  transform: translateX(-80px) translateY(5px) !important;
}

.login,
.cadastro {
  .main__header .btn-primary {
    display: none;
  }
}

/**********
  RADIOBUTTON
***********/

// Variables
$border-color: var(--text-secondary);
$accent-color: var(--green-secondary);

// Radio
.radio {
  display: inline-block;
  position: relative;
  margin: 0;

  &__input {
    position: absolute;
    top: 4px;
    left: 0;
    width: 36px;
    height: 25px;
    opacity: 0;
    z-index: 0;
  }

  // Unchecked
  &__label {
    display: block;
    padding: 0 0 0 36px;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
      background-color: transparent;
      border: 2px solid $border-color;
      border-radius: 14px;
      z-index: 1;
      transition: border-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:after {
      content: "check_circle";
      position: absolute;
      top: -2px;
      left: 0;
      color: var(--green-secondary);
      border-radius: 50%;
      z-index: 2;
      transform: scale(0, 0);
      transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      font-family: "Material Symbols Outlined";
      font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
      font-size: 24px;
    }
  }

  // Checked
  &__input:checked + &__label {
    &:before {
      border-color: $accent-color;
    }

    &:after {
      transform: scale(1, 1);
    }
  }

  &.disabled {
    .radio__input:checked + .radio__label::before {
      border-color: transparent;
    }
    .radio__label {
      cursor: default;
      opacity: 1;
      &::before {
        cursor: default;
        border-color: var(--text-quaternary);
      }

      &::after {
        color: var(--text-quaternary);
      }
    }
  }

  // Focus
  // Uncomment for accessible outline
  // &__input:focus + &__label {
  //     &:before {
  //         outline: 1px dotted currentColor;
  //     }
  // }
}

/**********
   TAG
***********/
.tags {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;

  .tag {
    border-radius: 8px;
    background: var(--fills-fill-light-12, rgba(124, 128, 120, 0.12));
    display: flex;
    padding: 4px 8px;
    align-items: flex-start;
    gap: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--text-primary);

    &--payment {
      background: var(--fills-fill-light-8, rgba(124, 128, 120, 0.08));
      color: var(--text-secondary);

      &-status-P {
        background: var(--labels-alert-20, rgba(203, 153, 23, 0.12));
        color: #cb9917;
      }

      &-status-O {
        background: var(--labels-error-fill, rgba(186, 26, 26, 0.12));
        color: #ba1a1a;
      }
    }
  }
}

.mdc-linear-progress {
  position: absolute !important;
}

/**********
   PAGINA EMPRESARIAL
***********/

body.empresarial,
body.editar-dados {
  .main__header__row {
    .coln-right,
    .main__header__nav .nav-item {
      display: none;
    }
  }
}

.booking-conclusion__cln--3.disabled {
  .form-group__inline.disabled .form-control::placeholder,
  .disabled.form-group__inline--with-button .form-control::placeholder,
  .disabled.form-group__inline--radio .form-control::placeholder {
    color: var(--text-secondary);
    opacity: 0.38;
  }
}

body.dados-conta {
  .main__footer {
    border-top: none;

    &__breadcrumb {
      border-top: 0.5px solid var(--separator-border, rgba(65, 67, 60, 0.16));
    }
    .faq {
      display: none;
    }
  }
}

/**********
   MODAL
***********/
.modal {
  overflow-y: hidden;

  &.fade {
    left: unset;
    right: -100%;
    transition: right 0.2s linear, opacity 0.15s linear;

    &.show {
      right: 0;
    }
  }
}
.modal-backdrop {
  background: var(--fills-fill-light-20, rgba(124, 128, 120, 0.2));
  &.show {
    opacity: 1;
  }
}

.modal-dialog {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  width: 100%;

  @media (min-width: 768px) {
    right: 8px;
    top: 8px;
    max-width: 400px;
    width: 400px;
  }
}

.modal-content {
  overflow: hidden;
  height: 100vh;
  border-radius: 0;
  background: var(--background-background, #f1f3ee);

  @media (min-width: 768px) {
    height: calc(100vh - 16px);
    border-radius: 16px;
    border: 1px solid var(--separator-border, rgba(65, 67, 60, 0.16));
  }
}

body.modal-open {
  overflow-x: hidden;
}
