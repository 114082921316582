@font-face {
  font-family: "Degular Display";
  src: url("../fonts/degulardisplay-regular.woff2") format("woff2"),
    url("../fonts/degulardisplay-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Degular Display";
  src: url("../fonts/degulardisplay-medium.woff2") format("woff2"),
    url("../fonts/degulardisplay-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Degular Display";
  src: url("../fonts/degulardisplay-semibold.woff2") format("woff2"),
    url("../fonts/degulardisplay-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

/***************************
        TIPOGRAFIA
****************************/
p,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  &.typography {
    margin-bottom: 0;
  }
}

.typography {
  &.text-black {
    color: var(--text-black);
  }

  &.text-primary {
    color: var(--text-primary) !important;
  }

  &.text-green-secondary {
    color: var(--green-secondary) !important;
  }

  &.text-secondary {
    color: var(--text-secondary) !important;
  }

  &.text-tertiary {
    color: var(--text-tertiary) !important;
  }

  &.text-quaternary {
    color: var(--text-quaternary) !important;
  }

  &.text-error {
    color: var(--labels-error, #ba1a1a);
  }

  &.title {
    font-family: "Degular Display";
    font-weight: 500;
    line-height: 100%;
    color: var(--text-black);

    &.semibold {
      font-weight: 600;
    }

    &.regular {
      font-weight: 400;
    }
  }

  &.body {
    font-family: "Inter";
    line-height: normal;
  }

  &.t-48 {
    font-size: 48px;
    @media (max-width: 767.98px) {
      font-size: 36px;
    }
  }

  &.t-36 {
    font-size: 36px;
  }

  &.t-24 {
    font-size: 24px;
  }

  &.t-20 {
    font-size: 20px;
  }

  &.t-18 {
    font-size: 18px;
  }

  &.t-16 {
    font-size: 16px;
  }

  &.t-14 {
    font-size: 14px;
  }

  &.t-12 {
    font-size: 12px;
  }
}

/***************************
        ESPAÇAMENTO
****************************/

.p-bottom-quarck {
  padding-bottom: var(--spacing-stack-quarck);
}

.p-bottom-quarck-double {
  padding-bottom: calc(var(--spacing-stack-quarck) * 2);
}

.p-bottom-nano {
  padding-bottom: var(--spacing-stack-nano);
}

.p-bottom-xxxs {
  padding-bottom: var(--spacing-stack-xxxs);
}

.p-bottom-xxs {
  padding-bottom: var(--spacing-stack-xxs);
}

.p-bottom-xs {
  padding-bottom: var(--spacing-stack-xs);
}

.p-bottom-sm {
  padding-bottom: var(--spacing-stack-sm);
}

.p-bottom-md {
  padding-bottom: var(--spacing-stack-md);
}

.p-bottom-lg {
  padding-bottom: var(--spacing-stack-lg);
}

.p-bottom-xl {
  padding-bottom: var(--spacing-stack-xl);
}

.m-bottom-quarck {
  margin-bottom: var(--spacing-stack-quarck) !important;
}

.m-bottom-nano {
  margin-bottom: var(--spacing-stack-nano);
}

.m-bottom-xxxs {
  margin-bottom: var(--spacing-stack-xxxs);
}

.m-bottom-xxs {
  margin-bottom: var(--spacing-stack-xxs);
}

.m-bottom-xs {
  margin-bottom: var(--spacing-stack-xs);
}

.m-bottom-sm {
  margin-bottom: var(--spacing-stack-sm);
}

.m-bottom-md {
  margin-bottom: var(--spacing-stack-md);
}

.m-bottom-lg {
  margin-bottom: var(--spacing-stack-lg);
}

.m-bottom-xl {
  margin-bottom: var(--spacing-stack-xl);
}

.space-20 {
  margin-bottom: 20px;
}
