:root {
  // CORES
  --green-primary: #c4f54b;
  --green-secondary: #173016;
  --background: #f1f3ee;
  --surface: #ffffff;
  --blur-overlay: #f1f3eecc;
  --fill-light-8: #7c807814;
  --fill-light-12: #7c80781f;
  --fill-light-16: #7c807829;
  --fill-light-20: #7c807829;
  --text-black: #000000;
  --text-primary: #41433c;
  --text-secondary: #41433c99;
  --text-tertiary: #41433c4d;
  --text-quaternary: #41433c2e;
  --border: #41433c29;
  --labels-error: #ba1a1a;

  // ESPAÇAMENTO
  --spacing-stack-quarck: 4px;
  --spacing-stack-nano: 12px;
  --spacing-stack-xxxs: 16px;
  --spacing-stack-xxs: 24px;
  --spacing-stack-xs: 32px;
  --spacing-stack-sm: 40px;
  --spacing-stack-md: 48px;
  --spacing-stack-lg: 56px;
  --spacing-stack-xl: 64px;
  --spacing-stack-xxl: 80px;
  --spacing-stack-xxxl: 128px;
  --spacing-stack-huge: 160px;
  --spacing-stack-giant: 200px;

  --mdc-linear-progress-track-height: 3px;
  --mdc-linear-progress-active-indicator-color: var(--green-secondary);
}
